import React from "react"
import styles from "./press-css-module.module.scss"
import landingLogo from "../../assets/press/landingLogo.png"
import news1 from "../../assets/press/news1.png"
import news2 from "../../assets/press/news2.png"
import leader1 from "../../assets/press/leader1.png"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Layout, CustomCarouselArrow, PressCoverage, SEO } from "../../components"
import { Link } from "gatsby"

const press = ({ data }) => {
  const articles = data.allMarkdownRemark.nodes
  const postSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    focusOnSelect: false,
    adaptiveHeight: true,
    nextArrow: <CustomCarouselArrow type="NextRound" />,
    prevArrow: <CustomCarouselArrow type="PrevRound" />,
    draggable: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          accessibility: false,
          arrows: false,
        },
      },
    ],
  }

  return (
    <div>
      <Layout>
      <SEO 
      title="Press Release |Solv in News |Press Coverage" 
      description="Solv empowers MSMEs via their B2B marketplace app & financial services. Read more on how Solv is driving innovation to accelarate growth for small businesses."
      />
        <section className={styles.container}>
          <div className={styles.about}>
            <img src={landingLogo} alt="logo" />
            <div className={styles.growthPlatform}>
              <div className={[styles.heading, "heading1SemiBold"].join(" ")}>
                News about our business, growth and people
              </div>
            </div>
          </div>
          {/* <div className={styles.news}>
             <div className={[styles.heading, "heading1Medium"].join(" ")}>
              Latest News
            </div>
            <div className={styles.newsContainer}>
              <div className={styles.newsItem}>
                <img src={news1} alt="logo" />
                <div className={styles.heading}>
                  Beyond banking: SC Ventures’ Solv digital platform aims to win
                  the Indian SME market
                </div>
                <div className={styles.description}>
                  Small and medium-sized enterprises (SMEs) form the backbone of
                  many economies around the world. And India is no different.
                </div>
              </div>
              <div className={styles.newsItem}>
                <img src={news2} alt="logo" />
                <div className={styles.heading}>
                  Solv Launches Festive Offers for Small Business Community to 
                  Help them Earn More this Festival
                </div>
                <div className={styles.description}>
                Going forward, Solv also aims to source and deliver raw materials for the foundations 
                flagship mid-day meal scheme that provides fresh
                </div>
              </div>
            </div> 
            <hr></hr>
          </div> */}

          <div className={styles.news}>
            <div className={styles.coverageSection}>
              <div className={[styles.heading, "heading1Medium"].join(" ")}>
                Press Coverage
              </div>
              <PressCoverage></PressCoverage>
            </div>
          </div>
        </section>
        <section className={styles.leadershipSection}>
          <div className={[styles.heading, "heading1Medium"].join(" ")}>
            Thought Leadership
          </div>
          <div className={styles.subHeading}>
            Our executives often lend their opinions and insights to leading
            publications
          </div>
          <Slider {...postSettings}>

          <>
              <a
                className={styles.internalPost}
                href="https://inc42.com/resources/building-a-resilient-startup-strategies-for-surviving-economic-downturns-for-indian-startups/"
                target="_blank"
              >
                {/* <div>
                  <img src={leader1} alt="newsPic" />
                </div> */}
                <div className={styles.contentSection}>
                  <div className={styles.heading}>
                      Building A Resilient Startup: Strategies For Surviving Economic Downturns For Indian Startups
                  </div>
                  <div className={styles.subHeading}>Inc42</div>
                  <div className={styles.content}>
                    <div className={styles.quotes}>&#8223;</div>
                    <div className={styles.text}>
                      … The entrepreneurial journey is fraught with challenges, from finding the right product-market fit (PMF) to acquiring paying customers and securing funding.....
                    </div>
                    <div className={styles.quotes}>&#8222;</div>
                  </div>
                  <div className={styles.author}>Amit Bansal</div>
                  <div className={styles.designation}>CEO</div>
                </div>
              </a>
            </>

          <>
              <a
                className={styles.internalPost}
                href="https://www.entrepreneur.com/en-in/news-and-trends/4-tips-to-have-a-highly-productive-and-successful-team-at/465120"
                target="_blank"
              >
                {/* <div>
                  <img src={leader1} alt="newsPic" />
                </div> */}
                <div className={styles.contentSection}>
                  <div className={styles.heading}>
                    4 Tips To Have a Highly Productive and Successful Team at any Start-up
                  </div>
                  <div className={styles.subHeading}>ENTREPRENEUR INDIA</div>
                  <div className={styles.content}>
                    <div className={styles.quotes}>&#8223;</div>
                    <div className={styles.text}>
                      … culture is the set of shared beliefs, values, customs and behaviours that define a company. An organisational culture based on a strong set of values helps attract and retain talent, foster a positive and productive work....
                    </div>
                    <div className={styles.quotes}>&#8222;</div>
                  </div>
                  <div className={styles.author}>Amit Bansal</div>
                  <div className={styles.designation}>CEO</div>
                </div>
              </a>
            </>


            <>
              <a
                className={styles.internalPost}
                href="https://www.indianretailer.com/article/retail-people/trends/how-bright-is-the-future-of-india-s-footwear-industry.a7994/"
                target="_blank"
              >
                {/* <div>
                  <img src={leader1} alt="newsPic" />
                </div> */}
                <div className={styles.contentSection}>
                  <div className={styles.heading}>
                      How Bright is the Future of India's Footwear Industry
                  </div>
                  <div className={styles.subHeading}>Indian Retailer</div>
                  <div className={styles.content}>
                    <div className={styles.quotes}>&#8223;</div>
                    <div className={styles.text}>
                      … Hyper-local logistics are unavailable and trade is run chiefly by cluster-based suppliers. This is where B2B e-commerce platforms can make a huge difference and contribute to the market by playing the role of an intermediary that facilitates discovery, credit, and logistics....
                    </div>
                    <div className={styles.quotes}>&#8222;</div>
                  </div>
                  <div className={styles.author}>Amit Bansal</div>
                  <div className={styles.designation}>CEO</div>
                </div>
              </a>
            </>

            <>
              <a
                className={styles.internalPost}
                href="https://www.entrepreneur.com/article/426851"
                target="_blank"
              >
                {/* <div>
                  <img src={leader1} alt="newsPic" />
                </div> */}
                <div className={styles.contentSection}>
                  <div className={styles.heading}>
                    India's Mobile Phone And Accessories Market Set To Boom
                  </div>
                  <div className={styles.subHeading}>ENTREPRENEUR INDIA</div>
                  <div className={styles.content}>
                    <div className={styles.quotes}>&#8223;</div>
                    <div className={styles.text}>
                      … PLI scheme is a welcome move for the Indian mobile manufacturing ecosystem, attracting top players like Apple and Samsung to increase their Make in India footprint and make India their export hub....
                    </div>
                    <div className={styles.quotes}>&#8222;</div>
                  </div>
                  <div className={styles.author}>Amit Bansal</div>
                  <div className={styles.designation}>CEO</div>
                </div>
              </a>
            </>

            <>
              <a
                className={styles.internalPost}
                href="https://www.businesstoday.in/opinion/columns/story/how-anchor-led-supply-chain-finance-can-support-retailers-and-small-businesses-318161-2022-01-06"
                target="_blank"
              >
                {/* <div>
                  <img src={leader1} alt="newsPic" />
                </div> */}
                <div className={styles.contentSection}>
                  <div className={styles.heading}>
                      How anchor-led supply chain finance can support retailers and small businesses
                  </div>
                  <div className={styles.subHeading}>Business Today</div>
                  <div className={styles.content}>
                    <div className={styles.quotes}>&#8223;</div>
                    <div className={styles.text}>
                      …One of the signs of a healthy economy is a seamless harmony between FIs and MSMEs. Anchor-led 
                      supply chain financing removes credit hurdles and liberates FIs, MSMEs, buyers and retailers..
                    </div>
                    <div className={styles.quotes}>&#8222;</div>
                  </div>
                  <div className={styles.author}>Amit Bansal</div>
                  <div className={styles.designation}>CEO</div>
                </div>
              </a>
            </>
            <>
              <a
                className={styles.internalPost}
                href="https://www.indianretailer.com/article/technology/perspectives/why-the-demand-for-b2b-e-commerce-is-only-going-to-grow-in-2022.a7640/"
                target="_blank"
              >
                <div className={styles.contentSection}>
                  <div className={styles.heading}>
                  Why The Demand For B2B E-commerce Is Only Going To Grow In 2022?
                  </div>
                  <div className={styles.subHeading}>Indian Retailer</div>
                  <div className={styles.content}>
                    <div className={styles.quotes}>&#8223;</div>
                    <div className={styles.text}>
                      …The current $700 billion+ B2B retail market has the potential to reach $1.3 trillion by 2025; this feat 
                      can only be achieved through sustainable, user friendly and importantly, affordable tech solutions which can 
                      create a participative economy..
                    </div>
                    <div className={styles.quotes}>&#8222;</div>
                  </div>
                  <div className={styles.author}>Amit Bansal</div>
                  <div className={styles.designation}>
                    CEO
                  </div>
                </div>
              </a>
            </>
            {/* <>
              <a
                className={styles.internalPost}
                href="http://bwdisrupt.businessworld.in/article/B2B-E-Commerce-Will-Define-The-Next-10-Years-Of-India-s-Economic-Growth/27-10-2021-410083/"
                target="_blank"
              >
                <div className={styles.contentSection}>
                  <div className={styles.heading}>
                    B2B E-Commerce Will Define The Next 10 Years Of India’s Economic Growth
                  </div>
                  <div className={styles.subHeading}>Business World</div>
                  <div className={styles.content}>
                    <div className={styles.quotes}>&#8223;</div>
                    <div className={styles.text}>
                      …MSMEs adaptability to e-commerce and its benefits have already been established by the Government e-Marketplace’s (GeM) 
                      dazzling success. The GeM was launched in 2016 as a Government-to-Business (G2B) e-commerce platform, featuring an end-to-end 
                      online marketplace to facilitate the procurement of common use goods and services required by central and state ministries, 
                      government departments, public sector undertakings and other local institutions at the best price and quality through transparent and efficient means..
                    </div>
                    <div className={styles.quotes}>&#8222;</div>
                  </div>
                  <div className={styles.author}>Amit Bansal</div>
                  <div className={styles.designation}>
                  CEO
                  </div>
                </div>
              </a>
            </>
            <>
              <a
                className={styles.internalPost}
                href="https://www.entrepreneur.com/article/389024"
                target="_blank"
              >
                <div className={styles.contentSection}>
                  <div className={styles.heading}>
                    Embedded Financing In B2B E-commerce Can Catalyse the MSME Sector
                  </div>
                  <div className={styles.subHeading}>ENTREPRENEUR INDIA</div>
                  <div className={styles.content}>
                    <div className={styles.quotes}>&#8223;</div>
                    <div className={styles.text}>
                      …The fintech industry is growing with new innovations such as AI for predictive analysis and digital-only banking. Amidst India’s 
                      credit-starved MSME sector, the power of alternate credit scoring mechanisms is becoming more and more visible..
                    </div>
                    <div className={styles.quotes}>&#8222;</div>
                  </div>
                  <div className={styles.author}>Amit Bansal</div>
                  <div className={styles.designation}>CEO</div>
                </div>
              </a>
            </> */}
            {/* <>
              <a
                className={styles.internalPost}
                href="https://inc42.com/resources/10-ways-artificial-intelligence-will-benefit-the-indian-msme-sector-in-2021/"
              >
                <div className={styles.contentSection}>
                  <div className={styles.heading}>
                    10 Ways Artificial Intelligence Will Benefit The Indian MSME
                    Sector In 2021
                  </div>
                  <div className={styles.subHeading}>Entrepreneur Magazine</div>
                  <div className={styles.content}>
                    <div className={styles.quotes}>&#8223;</div>
                    <div className={styles.text}>
                      …Advancements in artificial intelligence (AI) and machine
                      learning (ML) driven technologies are assisting the entire
                      business pipeline in discovery, conversations,
                      transactions and fulfilment for smart decision making.
                      These platforms are progressively becoming available even
                      for small businesses as well.
                    </div>
                    <div className={styles.quotes}>&#8222;</div>
                  </div>
                  <div className={styles.author}>Kishore AK</div>
                  <div className={styles.designation}>CTO</div>
                </div>
              </a>
            </> */}
          </Slider>
        </section>
        <section>
          <div className={styles.pressReleaseContainer}>
            <div className={[styles.heading, "heading1Medium"].join(" ")}>
              Press Release
            </div>
            {articles.map((ele, index) => {
              return (
                <div key={index} className={styles.pressItemContainer}>
                  <div className={styles.date}>
                    Posted: {ele.frontmatter.date}
                  </div>
                  <div className={styles.title}>
                    <Link to={ele.fields.slug}>{ele.frontmatter.title}</Link>
                  </div>
                  <div className={styles.description}>
                    {ele.frontmatter.description}
                  </div>
                  <div className={styles.hyperlink}>
                    <Link to={ele.fields.slug}>Read More &#62;</Link>
                  </div>
                  <hr></hr>
                </div>
              )
            })}
          </div>
        </section>
        <section>
          <div className={styles.mediaContactContainter}>
            <div className={styles.mediaContactHeading}>Media Contact</div>
            <div className={styles.mediaContactAddress}>
              Indiqube Edge, Bellandur, Bengaluru, Karnataka - 560103
            </div>
            <div className={styles.mediaContactHyperlink}>
              <a href="mailto: media@solvezy.com">media@solvezy.com</a>
            </div>
            {/* <hr></hr>
            <div className={styles.mediaContactHeading}>Media Kit</div>
            <div className={styles.mediaContactHyperlink}>
              <a href={`/Media_Kit.zip`} download>
                Download here
              </a>
            </div> */}
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default press

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(press)/" } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD  MMMM, YYYY")
          title
          description
          place
        }
      }
    }
  }
`
